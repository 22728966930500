import React, { useEffect, useRef } from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import poster from "../images/ar-poster.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/layout/heading"
import { useWindowSize } from "@react-hook/window-size/throttled"
import ARViewer from "../components/ar-viewer"
import Logo from "../svg/inline/logo.svg"
import { StaticImage } from "gatsby-plugin-image"

const ARPage: React.FC = () => {
  const intl = useIntl()
  const headingContainer = useRef<HTMLDivElement>(null)
  const arContainer = useRef<HTMLDivElement>(null)
  const [windowWidth, windowHeight] = useWindowSize()

  useEffect(() => {
    if (headingContainer.current && arContainer.current) {
      const orientation = windowWidth > windowHeight ? "landscape" : "portrait"

      if (orientation === "landscape") {
        arContainer.current.style.height = ""
      } else {
        arContainer.current.style.height = `calc(100vh - ${
          headingContainer.current.getBoundingClientRect().height
        }px - 1rem)`
      }
    }
  }, [headingContainer, arContainer, windowWidth])

  return (
    <Layout hideAgeCheck slimFooter>
      <SEO title={intl.formatMessage({ id: "page.ar.title" })} />

      <section className="flex items-center gap-4 portrait:flex-col landscape:flex-row">
        <div
          ref={headingContainer}
          className="flex flex-col items-center justify-center space-y-4 px-container portrait:w-full portrait:pt-[9vw] landscape:w-[40%]"
        >
          <a href="https://www.145vodka.com/">
            <Logo className="text-white hover:text-white portrait:h-[200px] portrait:w-[200px] landscape:h-[250px] landscape:w-[250px]" />
          </a>
          <div className="flex flex-col items-center text-center">
            <Heading importance="h1" className="text-[16vw] sm:text-[7.5vw]">
              {intl.formatMessage({ id: "page.ar.heading" })}
            </Heading>
            <p className="mt-2 max-w-screen-sm leading-none">
              {intl.formatMessage({ id: "page.ar.paragraph" })}
            </p>
            <a
              href="https://www.145vodka.com"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-6 flex items-center justify-center rounded-full bg-[#351d5c] px-[50px] pb-[8px] pt-[12px] font-condensed text-[24px] font-bold uppercase leading-none text-white hover:bg-[#180537] hover:text-white"
            >
              Home
            </a>
          </div>
        </div>
        <div
          ref={arContainer}
          className="relative portrait:min-h-[60vh] portrait:w-full landscape:h-[100vh] landscape:w-[60%]"
        >
          <ARViewer
            src="/ar.glb"
            poster={poster}
            qrCode={
              <StaticImage
                src="../images/ar-qr-code.png"
                alt={intl.formatMessage({
                  id: "model_viewer.qr_modal.qr_image_alt",
                })}
                className="h-[200px] w-[200px]"
              />
            }
          />
        </div>
      </section>
    </Layout>
  )
}

export default ARPage
