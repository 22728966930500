import React from "react"
import CloseIcon from "../../svg/inline/close.svg"
import { useIntl } from "gatsby-plugin-react-intl"

type Props = {
  onClose: () => void
  className?: string
  qrCode: React.ReactElement
}

const Modal: React.FC<Props> = ({ onClose, className = "", qrCode }) => {
  const intl = useIntl()

  return (
    <div
      className={`flex w-[450px] max-w-[80vw] flex-col items-center justify-center space-y-1 rounded-[20px] bg-white p-[30px] text-left leading-none text-black ${className}`}
    >
      <button
        onClick={onClose}
        className="m-0 flex h-[30px] w-[30px] items-center self-end border-none bg-none p-0 outline-none"
      >
        <CloseIcon className="h-[30px] w-[30px]" />
      </button>
      <p className="text-[20px]">
        {intl.formatMessage({ id: "model_viewer.qr_modal.info" })}
      </p>
      {qrCode}
      <p className="text-[16px]">
        {intl.formatMessage({
          id: "model_viewer.qr_modal.min_requirements",
        })}
      </p>
    </div>
  )
}

export default Modal
