import React, { useEffect, useRef, useState } from "react"
import ARIcon from "../svg/inline/ar.svg"
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer"
import Modal from "./ar-viewer/modal"

type Props = {
  src: string
  poster: React.ReactElement
  qrCode: React.ReactElement
}

const ARViewer: React.FC<Props> = ({ src, poster, qrCode }) => {
  const modelRef = useRef<ModelViewerElement & HTMLElement>(null)
  const [modelViewerLoaded, setModelViewerLoaded] = useState(false)
  const [canActivateAR, setCanActivateAR] = useState<boolean | null>(null)
  const [showARInfoModal, setShowARInfoModal] = useState(false)

  useEffect(() => {
    // @ts-ignore ignore missing type declarations
    import("@google/model-viewer").then(() => {
      setModelViewerLoaded(true)
    })
  }, [])

  useEffect(() => {
    if (modelRef.current && modelViewerLoaded) {
      setCanActivateAR(modelRef.current.canActivateAR)
    }
  }, [modelRef, modelViewerLoaded])

  const handleShowARInfoModal = () => setShowARInfoModal(true)
  const handleCloseARInfoModal = () => setShowARInfoModal(false)

  return (
    <>
      {/* @ts-ignore model-viewer is pretty valid */}
      <model-viewer
        ref={modelRef}
        loading="eager"
        camera-orbit="20deg 130deg 50%"
        alt=""
        src={src}
        ar
        ar-modes="webxr scene-viewer quick-look"
        poster={poster}
        seamless-poster
        shadow-intensity="1"
        camera-controls
        class="h-full w-full [--poster-color:#000] [--progress-bar-color:#f33bcb] [background-color:unset] focus:outline-none focus-visible:outline-none"
      >
        <button
          slot="ar-button"
          className="absolute right-[20px] top-[20px] flex h-[40px] w-[40px] items-center justify-center rounded-[50%] border-none bg-razzle-dazzle-rose p-[4px] text-white"
        >
          <ARIcon className="h-full w-full" />
        </button>
        {/* @ts-ignore model-viewer is pretty valid */}
      </model-viewer>
      {canActivateAR === false ? (
        <button
          data-testid="show-ar-info-modal"
          onClick={handleShowARInfoModal}
          className="absolute left-1/2 top-1/2 flex h-[80px] w-[80px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-[50%] bg-razzle-dazzle-rose p-[4px] text-white transition-transform duration-100 ease-in-out hover:scale-[1.3]"
        >
          <ARIcon className="h-[55px] w-[55px] shrink-0" />
        </button>
      ) : (
        ""
      )}
      {showARInfoModal && (
        <Modal
          onClose={handleCloseARInfoModal}
          className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2"
          qrCode={qrCode}
        />
      )}
    </>
  )
}

export default ARViewer
